import React from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Link, graphql } from 'gatsby';
import { Helmet } from "react-helmet";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

/**
 * This component is used to render the blog page.
 *
 * @param {object} data The data object returned from the graphql query.
 * @returns {*} The page component.
 */
const ContentfulPage = ({ data }) => {
  // destructure the nodes from the data
  const { nodes } = data.allContentfulPageBlogPost;

  return (
    // return a fragment
    <>
      {/* render the page wrapper component */}
      <PageWrapper
        // pass the header and footer props
        headerConfig={{
          // use light theme
          theme: "light",
          // set the button text
          buttonText: "Contact us",
        }}
        footerConfig={{
          // use light theme
          theme: "light",
          // set the page
          page: "akkadian-home",
          // set the style
          style: "style2", //style1, style2
        }}
      >
        {/* render the helmet component */}
        <Helmet>
          {/* set the page title */}
          <title>Akkadian Health - Blog | ADHD and Mental Health</title>
          <meta
            name="description"
            content="Stay updated with the latest news, articles, and insights on ADHD and mental health from Akkadian Health’s experts."
          />          
        </Helmet>
        {/* render the inner banner container */}
        <div className="inner-banner bg-default-2">
          <Container>
            {/* render the row and column */}


            <Row className="justify-content-center mt-md-6 pt-24 pt-lg-29">
              <Col lg="9" xl="8">
                {/* render the text */}
                <div className="px-md-12 text-center mb-11 mb-lg-14">
                  <h1 className="title gr-text-3 mb-9 mb-lg-12">Coming Soon</h1>
                </div>
              </Col>
            </Row>


{/*  */}
          </Container>{" "}
          {/* close the container */}
        </div>
        {/* close the inner banner */}
      </PageWrapper>
    </>
  );
};

const options = {
  renderText: text => {
    return (
      <p key={text.split(' ').join('')}>{text}</p>
    );
  }
};


export const query = graphql`
  query {
    allContentfulPageBlogPost(filter: { node_locale: { eq: "en-US" } }){
      nodes {
        id
        internalName
        seoFields {
          pageTitle
          nofollow
          noindex
        }
        slug
        author {
          name
          avatar {
            gatsbyImageData(width: 200)
            file {
              url
            }
          }          
        }
        publishedDate(formatString: "MMM Do, YYYY")
        title
        shortDescription {
          shortDescription
        }
        featuredImage {
          title
          description
          gatsbyImageData(layout: CONSTRAINED)
        }
        content {
          raw
        }
      }
    }
  }
`;

export default ContentfulPage;
